<script setup lang="ts">
  import SiteHeader from '~/components/site/SiteHeader.vue'
  import { useCookieConsentHelpers } from '~/composables/useCookieConsentHelpers'
  import FetchLoading from '~/components/loadings/FetchLoading.vue'

  onMounted(async () => {
    await nextTick(() => {
      useCookieConsentHelpers().addMainDivStyles()
      useCookieConsentHelpers().addButtonStyles()
      useCookieConsentHelpers().disableCookieManagementButton()
    })
  })
</script>
<template>
  <div class="flex min-h-screen flex-col">
    <FetchLoading />
    <Head>
      <Meta
        property="og:type"
        content="website" />
      <Meta property="og:image" />
      <Meta property="og:title" />
      <Meta property="og:description" />
      <Meta property="og:url" />
      <Meta name="description" />
      <Meta name="twitter:image" />
      <Meta name="twitter:card" />
      <Meta name="twitter:title" />
      <Meta name="twitter:description" />
    </Head>
    <CookieControl locale="pt" />
    <SiteHeader />
    <div class="flex-grow">
      <slot />
    </div>
    <LazySiteNewsletter />
    <LazySiteFooter />
  </div>
</template>
