export function useCookieConsentHelpers() {
  const addButtonStyles = () => {
    const buttons = window.document.querySelectorAll(
      '.cookieControl__BarButtons button'
    )
    buttons.forEach((button) => {
      // @ts-ignore
      button.style.borderRadius = '8px'
      // @ts-ignore
      button.style.padding = '6px 14px'
      // @ts-ignore
      button.style.fontSize = '12px'
      // @ts-ignore
      button.style.fontWeight = 'bold'
      // @ts-ignore
      button.style.color = '#fff'
      // @ts-ignore
      button.style.backgroundColor = '#fb923c'
    })
  }

  const addMainDivStyles = () => {
    const mainDiv = window.document.querySelector('.cookieControl__Bar')
    const secondaryDiv = window.document.querySelector(
      '.cookieControl__BarContainer'
    )
    const paragraph = window.document.querySelector(
      '.cookieControl__BarContainer p'
    )
    if (mainDiv) {
      // @ts-ignore
      mainDiv.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)'
      // @ts-ignore
      // mainDiv.style.borderRadius = '8px'
      // @ts-ignore
      mainDiv.style.borderTop = '1px solid #E5E5E5'
    }
    if (secondaryDiv) {
      // @ts-ignore
      secondaryDiv.style.padding = '10px'
      // @ts-ignore
      secondaryDiv.style.alignItems = 'center'
    }
    if (paragraph) {
      // @ts-ignore
      paragraph.style.fontSize = '12px'
    }
  }

  const disableCookieManagementButton = () => {
    const buttonsDiv = window.document.querySelector(
      '.cookieControl__BarButtons'
    )
    if (buttonsDiv) {
      const buttons = buttonsDiv.getElementsByTagName('button')
      const lastButton = buttons[buttons.length - 1]
      if (lastButton) {
        lastButton.style.display = 'none'
      }
    }
  }

  return {
    addButtonStyles,
    addMainDivStyles,
    disableCookieManagementButton,
  }
}
